const datePicker = () => {
    $('body').on('click', '[data-toggle="datepicker"]', function() {
        if (!$(this).data('daterangepicker')) {
            $(this).daterangepicker({
                timePicker: $(this).data('time') ? $(this).data('time') : false,
                singleDatePicker: true,
                showDropdowns: true,
                minDate: $(this).data('min-date') ? $(this).data('min-date') : new Date(),
                opens: $(this).data('direction') ? $(this).data('direction') : "right",
                drops: $(this).data('drop') ? $(this).data('drop') : "up",
                locale: {
                    format: $(this).data('time-formate') ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm A'
                }
            }).focus();
        }
    });

    $('body').on('click', '[data-toggle="purchaseinvoicedatepicker"]', function() {
        if (!$(this).data('daterangepicker')) {
            $(this).daterangepicker({
                timePicker: $(this).data('time') ? $(this).data('time') : false,
                singleDatePicker: true,
                showDropdowns: true,
                minDate: $(this).data('min-date') ? $(this).data('min-date') : null,
                maxDate: $(this).data('max-date') ? $(this).data('max-date') : new Date(),
                opens: $(this).data('direction') ? $(this).data('direction') : "right",
                drops: $(this).data('drop') ? $(this).data('drop') : "up",
                locale: {
                    format: $(this).data('time-formate') ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm A'
                }
            }).focus();
        }
    });
};

export default datePicker
